export const PLANS_DETAILS = Object.freeze([
    {
        name: 'Registro de receitas e despesas',
        free: 'Ilimitado',
        premium: 'Ilimitado'
    },
    {
        name: 'Criação de planejamentos de gastos por categorias',
        free: 'Limitado',
        premium: 'Ilimitado'
    },
    {
        name: 'Análises e dicas financeiras com Inteligência Artificial',
        free: '',
        includedFree: true,
        premium: '',
        includedPremium: true
    },
    {
        name: 'Criação de objetivos financeiros',
        free: 'Limitado',
        premium: 'Ilimitado'
    },
    {
        name: 'Categorias financeiras personalizadas',
        free: '',
        includedFree: false,
        premium: '',
        includedPremium: true
    },
    {
        name: 'Importação de transações via arquivos OFX ou CSV',
        free: 'Limitado',
        premium: 'Ilimitado'
    },
]);

export const PLANS_DETAILS_MOBILE = Object.freeze({
    FREE: [
        'Registros ilimitados de receitas e despesas',
        'Cadastro limitado de planejamentos de gastos por categorias',
        'Análises e dicas financeiras com Inteligência Artificial',
        'Definição limitada de objetivos financeiros',
        'Importação limitada de transações via arquivos OFX ou CSV',
    ],
    PREMIUM: [
        'Registros ilimitados de receitas e despesas',
        'Cadastro ilimitado de planejamentos de gastos por categorias',
        'Análises e dicas financeiras com Inteligência Artificial',
        'Definição ilimitada de objetivos financeiros',
        'Cadastro ilimitado de categorias financeiras personalizadas',
        'Importação ilimitada de transações via arquivos OFX ou CSV'
    ]
});