<template>
  <VueFinalModal class="modal-dialog" :esc-to-close="escToClose" :click-to-close="clickToClose" content-class="modal"
    overlay-transition="vfm-fade" content-transition="vfm-fade">
    <div class="modal__header">
      <p class="modal__title">{{ title }}</p>
      <a @click="onClose" class="modal__close">
        <fa-icon icon="fa-solid fa-xmark" />
      </a>
    </div>
    <div class="modal__body" ref="modalContainer">
      <box-information v-if="shouldShowErrorBox" :messages="errorMessages" :title="errorTitle" type="error" />
      <slot></slot>
    </div>
    <div class="modal__footer">
      <button v-if="withCancelButton" @click="onCancel" class="btn button-outline modal__cancel">
        {{ cancelLabel }}
      </button>
      <slot name="footer">
        <div></div>
      </slot>
      <request-button :type="buttonTypeAttribute" class="modal__confirm" :label="confirmLabel" v-if="fetchAction"
        :fetch-action="confirmAction" @fetch-success="onFetchSuccess" @fetch-error="onFetchError"
        :class="buttonClass('button-primary')" />
      <button v-else :type="buttonTypeAttribute" @click="confirmAction" class="btn button-primary modal__confirm"
        :class="buttonClass('button-primary')">
        {{ confirmLabel }}
      </button>
    </div>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import { computed, ref } from "vue";
import BoxInformation from '../BoxInformation/BoxInformation.vue';
import RequestButton from '../RequestButton/RequestButton.vue';

export default {
  components: { VueFinalModal, RequestButton, BoxInformation },
  props: {
    title: String,
    confirmLabel: {
      type: String,
      default: "Confirmar",
    },
    cancelLabel: {
      type: String,
      default: "Cancelar",
    },
    buttonType: String,
    buttonSubmit: Boolean,
    escToClose: {
      type: Boolean,
      default: true
    },
    clickToClose: {
      type: Boolean,
      default: true
    },
    withCancelButton: {
      type: Boolean,
      default: true
    },
    fetchAction: Function,
    errorTitle: {
      type: String,
      default: "Ocorreu um erro"
    },
    errorMessages: Array
  },
  setup(props, { emit }) {
    const modalContainer = ref(null);

    const confirmAction = () => {
      if (props.buttonSubmit) {
        const form = modalContainer.value.querySelector('form');
        if (form) {
          form.requestSubmit();
        }
      }

      if (props.fetchAction) {
        return props.fetchAction()
      }

      onConfirm();
    }

    const onClose = () => {
      emit("close");
    };

    const onCancel = () => {
      emit("cancel");
    };

    const onConfirm = () => {
      emit("confirm");
    };

    const onFetchSuccess = (data) => {
      emit('fetch-success', data);
    }

    const onFetchError = (error) => {
      emit('fetch-error', error);
    }

    const buttonClass = (className) => {
      if (props.buttonType === "alert") {
        return `${className}--alert`;
      }

      if (props.buttonType === "warning") {
        return `${className}--warning`;
      }

      if (props.buttonType === "success") {
        return `${className}--success`;
      }

      return "";
    };

    const shouldShowErrorBox = computed({
      get() {
        return props.errorMessages?.length
      }
    })

    const buttonTypeAttribute = computed({
      get() {
        return props.buttonSubmit ? 'submit' : 'button'
      }
    })

    return {
      shouldShowErrorBox,
      buttonTypeAttribute,
      modalContainer,
      confirmAction,
      onCancel,
      onClose,
      buttonClass,
      onFetchSuccess,
      onFetchError
    };
  },
};
</script>

<style lang="scss" src="./Modal.scss" />
