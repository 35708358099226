<template>
    <div class="report-button">
        <button class="btn small button-primary" @click="openReport">
            {{ label }}
        </button>
    </div>
</template>

<script>
import { onMounted, reactive, computed } from "vue";
import { useModal } from "vue-final-modal";
import { eventBus } from "@/services/eventBusService";
import { useUserDataStore } from "@/store/userData";
import { getAiInsightsUsage } from "@/modules/dashboard/services/usageVerificationService";
import ReportModal from "../ReportModal/ReportModal.vue";

export default {
    props: {
        label: String,
        reportTitle: String,
        fetchAction: Function,
        context: String
    },
    setup(props) {
        const userStore = useUserDataStore();
        const state = reactive({
            limitReached: false,
            useCount: 0,
            firstOpen: true,
        })

        onMounted(() => {
            if (!userStore.isPremiumUser) {
                getAiInsightsUsage().then(data => {
                    setUsageLimitData(data);
                });
            }
        })

        const openPremiumOverlay = () => {
            eventBus.emit('showPremiumOverlay', {
                title: 'Você atingiu o limite mensal de uso dos relatórios do seu plano gratuito!',
            });
        }

        const openReport = () => {
            if (state.limitReached) {
                openPremiumOverlay();
                return;
            }

            if (!userStore.isPremiumUser && !state.firstOpen) {
                getUsageLimit();
            }

            if (state.firstOpen || userStore.isPremiumUser) {
                openReportModal()
            }

            state.firstOpen = false;
        }

        const getUsageLimit = () => {
            getAiInsightsUsage().then(data => {
                setUsageLimitData(data);

                if (state.limitReached) {
                    openPremiumOverlay();
                    return;
                }

                openReportModal();
            })
                .catch(openPremiumOverlay)
        }

        const setUsageLimitData = (data) => {
            state.limitReached = data.limitReached;
            state.useCount = data.useCount;
        }

        const { open: openReportModal, close: closeReportModal } = useModal({
            component: ReportModal,
            attrs: {
                title: props.reportTitle,
                fetchAction: props.fetchAction,
                context: props.context,
                usageCount: computed(() => state.useCount),
                onClose() {
                    closeReportModal();
                },
                onConfirm() {
                    closeReportModal();
                },
                onCancel() {
                    closeReportModal();
                }
            }
        });

        return { state, openReport }
    }
}
</script>

