<template>
    <div ref="footerMenu" class="footer-menu">
        <div v-if="state.selectedOptions.length" class="footer-menu__options-list"
            :class="['footer-menu__options-list', { active: state.selectedOptions.length }]">
            <ul>
                <li v-for="(option, index) in state.selectedOptions" :key="index">
                    <router-link :to="option.href" @click="selectItem(option)" class="footer-menu__sub-option">
                        <fa-icon class="footer-menu__icon" :icon="`fa-solid ${option.icon}`" />
                        <span>{{ option.name }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="footer-menu__options">
            <router-link v-for="(option, index) in menuOptions" :key="index" :to="option.href"
                @click.prevent="selectOption(option)" class="footer-menu__option"
                :class="{ 'footer-menu__option--selected': state.activeMenu === option.name }">
                <fa-icon class="footer-menu__icon" :icon="`fa-solid ${option.icon}`" />
                <span>{{ option.name }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import { onClickOutside } from "@vueuse/core";
import { reactive, ref, computed } from 'vue';
import { MOBILE_MENU_ROUTES } from '../SideMenu/menuRoutes';
import { useModal } from 'vue-final-modal';
import { eventBus } from "@/services/eventBusService";
import CreateTransactionModal from '@/modules/financial/components/CreateTransactionModal/CreateTransactionModal.vue';
import CreateTransferModal from '@/modules/financial/components/CreateTransferModal/CreateTransferModal.vue';

const TRANSACTION_TYPES = {
    REVENUE: { code: 0, name: 'Nova receita' },
    EXPENSE: { code: 1, name: 'Nova despesa' },
    TRANSFER: { code: 2, name: 'Nova transferência' }
};

export default {
    setup() {
        const menuOptions = MOBILE_MENU_ROUTES;
        const state = reactive({
            selectedOptions: [],
            activeMenu: null,
            transactionType: null,
        });

        const modalTitle = computed({
            get() {
                return state.transactionType === TRANSACTION_TYPES.REVENUE.code ? 'Nova receita' : 'Nova despesa'
            }
        })

        const selectOption = (option) => {
            if (option.options) {
                state.activeMenu = option.name;
                state.selectedOptions = option.options;
            } else {
                resetMenu();
            }
        };

        const resetMenu = () => {
            state.selectedOptions = [];
            state.activeMenu = null;
        }

        const openCreateTransaction = (option) => {
            switch (option.name) {
                case TRANSACTION_TYPES.REVENUE.name:
                    createTransaction(TRANSACTION_TYPES.REVENUE.code);
                    break;
                case TRANSACTION_TYPES.EXPENSE.name:
                    createTransaction(TRANSACTION_TYPES.EXPENSE.code);
                    break;
                case TRANSACTION_TYPES.TRANSFER.name:
                    createTransaction(TRANSACTION_TYPES.TRANSFER.code);
                    break;
            }
        };

        const createTransaction = (type) => {
            state.transactionType = type

            if (type === TRANSACTION_TYPES.TRANSFER.code) {
                openTransferModal();
                return;
            }

            openTransactionModal()
        }

        const selectItem = (option) => {
            openCreateTransaction(option);
            resetMenu();
        }

        const { open: openTransactionModal, close: closeTransactionModal } = useModal({
            component: CreateTransactionModal,
            attrs: {
                title: modalTitle,
                type: computed(() => state.transactionType),
                onClose() {
                    closeTransactionModal()
                },
                onConfirm() {
                    closeTransactionModal()
                },
                onCancel() {
                    closeTransactionModal()
                },
                onSuccess() {
                    eventBus.emit('reloadData')
                }
            }
        });

        const { open: openTransferModal, close: closeTransferModal } = useModal({
            component: CreateTransferModal,
            attrs: {
                onClose() {
                    closeTransferModal()
                },
                onConfirm() {
                    closeTransferModal()
                },
                onCancel() {
                    closeTransferModal()
                },
                onSuccess() {
                    eventBus.emit('reloadData');
                }
            }
        });

        const footerMenu = ref(null);
        onClickOutside(footerMenu, () => { resetMenu() });

        return {
            menuOptions,
            state,
            footerMenu,
            selectOption,
            resetMenu,
            selectItem
        };
    }
};
</script>

<style lang="scss" src="./FooterMenu.scss" />
