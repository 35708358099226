import requestService from '@services/requestService'

const USAGE_URL = '/usage'

export function getAiInsightsUsage() {
    return requestService.get(`${USAGE_URL}/ai/insights-limit`);
}

export function getTransactionsImportUsage() {
    return requestService.get(`${USAGE_URL}/transaction/import-limit`);
}