import requestService from '@services/requestService'

const TRANSACTIONS_URL = '/financial-transaction'

export function getTransactions(params) {
    return requestService.get(`${TRANSACTIONS_URL}`, { params });
}

export function getTransactionById(id) {
    return requestService.get(`${TRANSACTIONS_URL}/${id}`);
}

export function getTransactionsBalance(params) {
    return requestService.get(`${TRANSACTIONS_URL}/balance`, { params });
}

export function getTransactionsSimpleBalance(params) {
    return requestService.get(`${TRANSACTIONS_URL}/simple-balance`, { params });
}

export function saveTransaction(params) {
    return requestService.post(`${TRANSACTIONS_URL}`, params);
}

export function saveTransactionsList(fileType, params) {
    return requestService.post(`${TRANSACTIONS_URL}/list/${fileType}`, params);
}

export function updateTransaction(id, params) {
    return requestService.put(`${TRANSACTIONS_URL}/${id}`, params);
}

export function updateTransactionSituation(params) {
    return requestService.put(`${TRANSACTIONS_URL}/done`, params);
}

export function removeTransaction(params) {
    return requestService.post(`${TRANSACTIONS_URL}/delete`, params);
}

export function uploadTransactions(fileType, params) {
    return requestService.post(`${TRANSACTIONS_URL}/transfer/import/${fileType}`, params, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function getCategoryBalance(params) {
    return requestService.get(`${TRANSACTIONS_URL}/balance-category`, { params });
}

