<template>
    <div class="report-feedback">
        <div class="report-feedback__header">
            <h4 class="report-feedback__title">O que você achou deste relatório?</h4>
        </div>
        <div class="report-feedback__content">
            <div class="report-feedback__options">
                <button v-tooltip="'Não gostei'" class="report-feedback__option btn"
                    :class="{ active: state.rate === 1 }" @click="setFeedback(1)">
                    <fa-icon icon="fa-solid fa-thumbs-down" />
                </button>
                <button v-tooltip="'Gostei'" class="report-feedback__option btn"
                    :class="{ active: state.rate === 5 }" @click="setFeedback(5)">
                    <fa-icon icon="fa-solid fa-thumbs-up" />
                </button>
            </div>
        </div>
        <div class="report-feedback__comment">
            <label for="feedback-text">Deixe um comentário</label>
            <textarea id="feedback-text" v-model="state.comment" @change="onChange"> </textarea>
        </div>
    </div>
</template>

<script>
import { reactive } from "vue";

export default {
    name: "ReportFeedback",
    setup(props, { emit }) {
        const state = reactive({
            rate: null,
            comment: ''
        })

        const setFeedback = (type) => {
            state.rate = type;

            onChange();
        };

        const onChange = () => {
            emit('feedback-data', { rate: state.rate, comment: state.comment })
        }

        return {
            state,
            setFeedback,
            onChange,
        };
    },
};
</script>

<style lang="scss" src="./ReportFeedback.scss" />