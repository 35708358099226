<template>
  <div class="free-plan-box">
    <div class="free-plan-box__header">
      <h2 class="free-plan-box__title">Plano Free</h2>
      <p class="free-plan-box__description"></p>
    </div>
    <div class="free-plan-box__content">
      <div class="free-plan-box__items">
        <div class="free-plan-box__item" v-for="line in plansDetails" :key="line.name">
          <div class="free-plan-box__item-name">{{ line }}</div>
        </div>
      </div>
      Obtenha <strong>acesso ilimitado</strong> as funcionalidades da plataforma com o <strong>Plano Premium</strong>
      <button class="btn button-primary" @click="goToPlans">Conhecer plano Premium</button>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();

    const plansDetails = [
      'Cadastro ilimitado de receitas e despesas',
      'Criação limitada de planejamentos mensais de gastos por categorias',
      'Análises e dicas financeiras com IA',
      'Cadastro limitado de objetivos financeiros',
      'Importação limitada de transações via arquivos OFX ou CSV'
    ]

    const goToPlans = () => {
      router.push("/plans");
    }

    return {
      plansDetails,
      goToPlans
    }
  }
}
</script>

<style lang="scss" src="./FreePlanBox.scss" />
