<template>
  <Modal class="report-modal" :title="title" @close="onClose" @confirm="onConfirm"
    confirm-label="Entendi" :buttonType="buttonType" :with-cancel-button="false" :click-to-close="false"
    :esc-to-close="false"
    :class="{ 'report-modal--loading': state.isLoading || state.hasError }">
    <template #default>
      <div class="report-modal__content">
        <loader v-if="state.isLoading" loader-text="Aguarde enquanto analisamos suas finanças..."/>
        <div v-if="!state.isLoading && state.hasError" class="report-modal__content-error">
          <img src="@images/illustrations/error-person.svg" class="report-modal__image-error" />
          <p class="report-modal__text-error">{{ state.errorMessage }}</p>
        </div>
        <template v-if="!state.isLoading && !state.hasError && state.reportData">
          <div class="report-modal__report">
            <div v-if="!isPremiumUser" class="report-modal__usage-limit">
              <badge :value="getUsageLimitText" />
            </div>
            <div v-html="state.reportData"></div>
            <report-feedback @feedback-data="setFeedbackData" />
          </div>
        </template>
      </div>
    </template>
    <template v-if="false" #footer>
      <button class="report-modal__export btn button-outline">
        <fa-icon icon="fa-solid fa-file-arrow-down" />
      </button>
    </template>
  </Modal>
</template>

<script>
import { onMounted, reactive, computed } from 'vue';
import { sendFeedback } from '@/modules/common/services/feedback/feedbackService';
import { FEEDBACK_TRACERS } from '../../constants/reportConstants';
import { useUserDataStore } from "@/store/userData";
import Modal from "@/components/Modal/Modal.vue";
import Loader from '@/components/Loader/Loader.vue';
import ReportFeedback from '../ReportFeedback/ReportFeedback.vue';
import Badge from '@/components/Badge/Badge.vue';

export default {
  components: { Modal, Loader, ReportFeedback, Badge },
  props: {
    title: String,
    description: String,
    confirmLabel: String,
    buttonType: String,
    fetchAction: Function,
    context: String,
    usageCount: Number,
  },
  setup(props, { emit }) {
    const userStore = useUserDataStore();
    const state = reactive({
      reportData: null,
      isLoading: false,
      hasError: false,
      feedbackData: null,
      errorMessage: null,
    })

    const isPremiumUser = computed({
      get() {
        return userStore.isPremiumUser;
      }
    })

    const getUsageLimitText = computed({
      get() {
        return `Você possui ${props.usageCount - 1} uso(s) restante(s) este mês`
      }
    })

    const onClose = () => {
      handleSendFeedback();
      emit("close");
    };

    const onConfirm = () => {
      handleSendFeedback();
      emit("confirmAction");
    };

    onMounted(() => {
      if (!props.fetchAction || state.isLoading) {
        return;
      }

      const fetchPromise = props.fetchAction();

      if (!(fetchPromise instanceof Promise)) {
        state.isLoading = false;
        return fetchPromise;
      }

      state.isLoading = true;
      fetchPromise.then(onFetchSuccess).catch(onFetchError);
    })

    const onFetchSuccess = (data) => {
      state.reportData = data?.content;
      state.isLoading = false;
    }

    const onFetchError = (data) => {
      state.isLoading = false;
      state.hasError = true;
      state.errorMessage = data?.content || "Ocorreu um erro ao criar o relatório. Tente novamente mais tarde!"
    }

    const setFeedbackData = (data) => {
      state.feedbackData = data;
    }

    const handleSendFeedback = () => {
      if (!state.feedbackData || !props.context) {
        return;
      }

      const feedback = {
        rate: state.feedbackData.rate,
        comment: state.feedbackData.comment,
        tracer: FEEDBACK_TRACERS[props.context]
      }

      return sendFeedback(feedback);
    }

    return {
      state,
      isPremiumUser,
      getUsageLimitText,
      onClose,
      onConfirm,
      setFeedbackData
    };
  },
};
</script>

<style lang="scss" src="./ReportModal.scss" />
