<template>
    <div class="imported-transactions-list">
        <form @submit.prevent>
            <div v-if="isOfxFile" class="imported-transactions-list__infos">
                <h3>{{ state.importedData.name }}</h3>
                <div class="imported-transactions-list__infos-data">
                    <p>Total <strong>{{ toCurrency(state.importedData.totalValue) }}</strong></p>
                </div>
                <div class="form-group date-select">
                    <label for="data-input">Data de pagamento:</label>
                    <datepicker v-model="state.importedData.paymentDate" :value="state.importedData.paymentDate"
                        name="data-input" required />
                </div>
            </div>
            <data-table :is-loading="false" :columns="tableColumns" :data="state.importedData.items" :show-pagination="false"
                :register-per-page-qty="1000" hide-filters class="imported-transactions-list__table"
                sorted-by="transactionDate">
                <template #row="{ row }">
                    <template v-if="isMobileDevice">
                        <td id="description" class="imported-transactions-list__table--mobile">
                            <custom-input label="" :value="row.description" v-model="row.description" required
                                name="description" />
                            <div class="additional-infos">
                                <datepicker class="additional-infos__date" v-model="row.transactionDate"
                                    :value="row.transactionDate" name="data-input" />
                                <span class="additional-infos__value">
                                    <toggle-currency-visibility :value="Number(row.value)" />
                                </span>
                            </div>
                            <div class="select-infos">
                                <transaction-category-select label="Categoria" :value="row.categoryId"
                                    :fetch-on-load="false" :options="state.categoryOptions[row.type]"
                                    @change-category="(data) => onChangeData(data.id, 'categoryId', row.id)" />
                                <div class="additional-infos">
                                    <transaction-type-select label="Tipo" :value="row.type"
                                        @change-type="(data) => onChangeData(data, 'type', row.id)" />
                                    <button class="btn btn-remove button-outline"
                                        @click="removeTransaction(row)">Excluir</button>
                                </div>
                            </div>
                        </td>
                    </template>
                    <template v-else>
                        <td id="description">
                            <custom-input label="" :value="row.description" v-model="row.description" required
                                name="description" />
                        </td>
                        <td id="date">
                            <datepicker class="additional-infos__date" v-model="row.transactionDate"
                                :value="row.transactionDate" name="data-input" />
                        </td>
                        <td id="value">
                            <toggle-currency-visibility :value="Number(row.value)" />
                        </td>
                        <td id="type">
                            <transaction-type-select label="" :value="row.type"
                                @change-type="(data) => onChangeData(data, 'type', row.id)" />
                        </td>
                        <td id="category">
                            <transaction-category-select :disabled="hideTransfers(row.type)" label=""
                                :value="row.categoryId" :fetch-on-load="false"
                                :options="state.categoryOptions[row.type]" :required="!hideTransfers(row.type)"
                                @change-category="(data) => onChangeData(data.id, 'categoryId', row.id)" />
                        </td>
                        <td id="actions">
                            <button class="btn btn-remove button-outline"
                                @click="removeTransaction(row)">Excluir</button>
                        </td>
                    </template>
                </template>
            </data-table>
            <request-button class="imported-transactions-list__save" type="submit" v-if="!emptyList"
                :fetch-action="saveTransactions" @fetch-success="onSaveTransactionsSuccess"
                @fetch-error="onSaveTransactionsError" label="Salvar transações" />
        </form>
    </div>
</template>

<script>
import { onMounted, reactive, computed, watch } from 'vue';
import { formatFullDate } from '@/services/formatService';
import { formatCurrency } from '@/services/currencyService';
import { getCategories } from '../../services/category/categoryService';
import { showErrorToast, showSuccessToast } from '@/services/toastService';
import { isMobile } from '@/services/deviceService';
import { useModal } from "vue-final-modal";
import { saveTransactionsList } from '../../services/transactionsService'
import { getTracerByName } from '@modules/common/services/tracer/tracerService';
import { TRANSACTION_TYPES } from '../../constants/transactionConstants';
import RequestButton from '@/components/RequestButton/RequestButton.vue';
import CustomInput from '@/components/CustomInput/CustomInput.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import TransactionTypeSelect from '../TransactionTypeSelect/TransactionTypeSelect.vue';
import TransactionCategorySelect from '../TransactionCategorySelect/TransactionCategorySelect.vue';
import RemoveImportedTransactionModal from "../RemoveImportedTransactionModal/RemoveImportedTransactionModal.vue";
import FeedbackModal from "@modules/common/components/FeedbackModal/FeedbackModal.vue";
import Datepicker from '@/modules/common/components/Datepicker/Datepicker.vue';
import ToggleCurrencyVisibility from '@/modules/common/components/ToggleCurrencyVisibility/ToggleCurrencyVisibility.vue';

const FEEDBACK_TRACER = 'FIRST_IMPORT';

export default {
    components: { DataTable, TransactionTypeSelect, TransactionCategorySelect, CustomInput, RequestButton, Datepicker, ToggleCurrencyVisibility },
    props: {
        transactionsData: Object,
        fileType: String,
    },
    setup(props, { emit }) {
        const state = reactive({
            importedData: {
                name: '',
                totalValue: 0,
                paymentDate: null,
                ...props.transactionsData
            },
            showTable: false,
            categoryOptions: [[], []],
            transactionToRemove: null,
            allTransactionsValid: false,
        })

        const transactionsColumns = [
            {
                name: "Descrição",
                key: "description",
                sortable: true,
            },
            {
                name: "Data",
                key: "transactionDate",
                sortable: true,
            },
            {
                name: "Valor (R$)",
                key: "value",
                sortable: true,
            },
            {
                name: "Tipo",
                key: "type",
                sortable: true,
            },
            {
                name: "Categoria",
                key: "category",
                sortable: true,
            },
            {
                name: "",
                key: "actions",
                sortable: false,
            },
        ];

        onMounted(() => {
            Promise.all([getCategories({ Type: 0 }), getCategories({ Type: 1 })])
                .then(([revenueCategories, expensesCategories]) => {
                    state.categoryOptions[0] = revenueCategories.items;
                    state.categoryOptions[1] = expensesCategories.items;
                })
                .catch(getTransactionCategoriesError);
        });

        watch(() => props.transactionsData,
            (value) => state.importedData = {...state.importedData, ...value}, { deep: true});

        const isMobileDevice = computed({
            get() {
                return isMobile();
            }
        })

        const isOfxFile = computed({
            get() {
                return props.fileType === "ofx"
            }
        })

        const tableColumns = computed({
            get() {
                return isMobile() ? [{
                    name: "Descrição",
                    key: "description",
                    sortable: true,
                }] : transactionsColumns
            }
        })

        const emptyList = computed({
            get() {
                return !state.importedData.items?.length;
            }
        })

        const hideTransfers = (type) => {
            return type === TRANSACTION_TYPES.TRANSFER.code
        }

        const formatDate = (date) => {
            return formatFullDate(new Date(date))
        }

        const onChangeData = (data, prop, id) => {
            const transactionIndex = state.importedData.items?.findIndex(x => x.id === id);
            const transaction = state.importedData.items[transactionIndex]
            transaction[prop] = data;

            state.importedData.items[transactionIndex] = transaction;
        }

        const getTransactionCategoriesError = () => {
            showErrorToast("Ocorreu um erro ao buscar as categorias. Tente novamente.");
        };

        const removeTransaction = (transaction) => {
            const { description, id } = transaction;
            state.transactionToRemove = { description, id }

            openRemoveImportedTransactionModal()
        }

        const { open: openRemoveImportedTransactionModal, close: closeRemoveImportedTransactionModal } = useModal({
            component: RemoveImportedTransactionModal,
            attrs: {
                transactionId: computed(() => state.transactionToRemove.id),
                nameTransaction: computed(() => state.transactionToRemove.description),
                buttonType: 'alert',
                onClose() {
                    closeRemoveImportedTransactionModal()
                },
                onConfirm() {
                    removeTransactionToSave(state.transactionToRemove.id)
                    closeRemoveImportedTransactionModal()
                },
                onCancel() {
                    closeRemoveImportedTransactionModal()
                },
            }
        });

        const removeTransactionToSave = (id) => {
            const transactionIndex = state.importedData.items?.findIndex(x => x.id === id);
            if (transactionIndex < 0) {
                return;
            }
            state.importedData.items.splice(transactionIndex, 1);
        }

        const hasAllRequiredFields = (obj) => {
            const hasCategory = obj.type === TRANSACTION_TYPES.TRANSFER.code
                || obj.type !== TRANSACTION_TYPES.TRANSFER.code && obj.categoryId
            return obj.description && obj.transactionDate
                && hasCategory;
        }

        const saveTransactions = () => {
            if (!state.importedData.items) {
                return;
            }

            state.allTransactionsValid = state.importedData.items.every(hasAllRequiredFields);

            if (!state.allTransactionsValid || (!state.importedData.paymentDate && isOfxFile.value)) {
                return;
            }

            return saveTransactionsList(props.fileType, {
                infos: state.importedData,
                transactions: [...state.importedData.items]
            });
        }

        const onSaveTransactionsSuccess = () => {
            showSuccessToast("Transações salvas com sucesso, você pode conferir na listagem de transações.");
            emit('reset-form');

            if (!getTracerByName(FEEDBACK_TRACER)) {
                setTimeout(() => {
                    openFeedbackModal();
                }, 600)
            }
        }

        const onSaveTransactionsError = () => {
            showErrorToast("Ocorreu um erro ao salvar as transações. Tente novamente.");
        }

        const { open: openFeedbackModal, close: closeFeedbackModal } = useModal({
            component: FeedbackModal,
            attrs: {
                tracer: FEEDBACK_TRACER,
                description: 'importar suas transações.',
                onClose() {
                    closeFeedbackModal()
                },
                onConfirm() {
                    closeFeedbackModal()
                },
                onCancel() {
                    closeFeedbackModal()
                }
            }
        });

        const toCurrency = (value) => {
            return formatCurrency(value)
        }

        return {
            state,
            tableColumns,
            isMobileDevice,
            emptyList,
            isOfxFile,
            hideTransfers,
            formatDate,
            onChangeData,
            removeTransaction,
            saveTransactions,
            onSaveTransactionsSuccess,
            onSaveTransactionsError,
            toCurrency
        }
    }
}
</script>

<style lang="scss" src="./ImportedTransactionsList.scss" />
