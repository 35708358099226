import * as transactionsResource from './transactionsResource'
import { TRANSACTION_FREQUENCY_NAMES } from '../constants/transactionConstants';

export async function getTransactions(params) {
    const { data } = await transactionsResource.getTransactions(params);

    return data;
}

export async function getTransactionsBalance(params) {
    const { data } = await transactionsResource.getTransactionsBalance(params);

    return data;
}

export async function getTransactionsSimpleBalance(params) {
    const { data } = await transactionsResource.getTransactionsSimpleBalance(params);

    return data;
}

export async function getTransactionById(id) {
    const { data } = await transactionsResource.getTransactionById(id);

    return data;
}

export async function saveTransaction(params) {
    const { data } = await transactionsResource.saveTransaction(params);

    return data;
}

export async function saveTransactionsList(fileType, params) {
    const formattedTransactions = buildTransactionsListToSave(params);

    const { data } = await transactionsResource.saveTransactionsList(fileType, formattedTransactions);

    return data;
}

export async function updateTransaction(id, params) {
    const { data } = await transactionsResource.updateTransaction(id, params);

    return data;
}

export async function removeTransaction(id) {
    return await transactionsResource.removeTransaction(id);
}

export async function uploadTransactions(type, params) {
    const { data } = await transactionsResource.uploadTransactions(type, params);

    return data;
}

export async function getCategoryBalance(params) {
    const { data } = await transactionsResource.getCategoryBalance(params);

    return data;
}

export async function updateTransactionSituation(params) {
    const { data } = await transactionsResource.updateTransactionSituation(params);

    return data;
}

function buildTransactionsListToSave(data) {
    const { infos, transactions } = data;
    return transactions.map(item => {
        const newItem = { ...item };
        delete newItem.id;

        newItem.paidValue = newItem.paidValue || newItem.value;
        newItem.paidDate = newItem.paidDate || infos?.paymentDate;

        return newItem;
    });
}

export function getRecurrenceIcon(recurrenceType) {
      if (!recurrenceType) {
        return '';
      }

      return 'fa-solid fa-rotate';
}

export function getRecurrenceTooltip(transaction) {
    const REPEAT = 1;
    const FIXED = 2;

    const { recurrenceType, recurrenceFrequency, recurrenceInfo} = transaction

    if(recurrenceType === REPEAT) {
        return `Transação ${TRANSACTION_FREQUENCY_NAMES[recurrenceFrequency]} ${recurrenceInfo}`
    }

    if(recurrenceType === FIXED) {
        return `Transação recorrente ${TRANSACTION_FREQUENCY_NAMES[recurrenceFrequency]}`
    }

    return '';
}
