<template>
  <div class="premium-plan-box">
    <div class="premium-plan-box__header">
      <h2 class="premium-plan-box__title">Plano Premium
        <badge value="Anual" />
      </h2>
    </div>
    <div class="premium-plan-box__content">
      <div class="premium-plan-box__infos">
        <div v-if="!hideInfos" class="premium-plan-box__plan-info">
          <div class="premium-plan-box__items">
            <div class="premium-plan-box__item" v-for="line in plansDetails" :key="line">
              <div class="premium-plan-box__item-name">{{ line }}</div>
            </div>
          </div>
        </div>
        <div class="premium-plan-box__payment">
          <payment-type-box :payment-method="subscriptionData.paymentMethod" />
        </div>
      </div>
      <div class="premium-plan-box__expiration">
        <p v-if="hideInfos && subscriptionData.cancelAt" class="premium-plan-box__canceled-at">Cancelada em:
          <span>{{ formatDate(subscriptionData.cancelAt) }}</span>
        </p>
        <p v-if="!hideInfos" class="premium-plan-box__expires-in">Expira em:
          <span>{{ formatDate(subscriptionData.currentPeriodEnd) }}</span>
        </p>
        <template v-if="!hideInfos">
          <button v-if="subscriptionData.renewalCanceled" class="btn button-outline premium-plan-box__active-renew"
            @click="handleRenewalSubscription">Ativar renovação automática</button>
          <a v-else class="premium-plan-box__cancel-automatically-renew" @click="openCancelRenewalConfirmModal">Cancelar
            renovação automática</a>
          <a v-if="subscriptionData.isCancelable" class="premium-plan-box__cancel-subscription"
            @click="openCancellationConfirmModal">Cancelar assinatura</a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { cancelSubscription, renewalSubscription } from '../../services/billingService';
import { formatFullDate } from '@/services/formatService';
import { useModal } from "vue-final-modal";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { sendPixelTrack } from '@/modules/common/services/pixelFacebook/pixelFacebookService';
import Badge from '@/components/Badge/Badge.vue';
import PaymentTypeBox from '../PaymentTypeBox/PaymentTypeBox.vue';
import ConfirmationModal from '@/modules/common/components/ConfirmationModal/ConfirmationModal.vue';

export default {
  components: { Badge, PaymentTypeBox },
  props: {
    subscriptionData: Object,
    hideInfos: Boolean
  },
  setup(props, { emit }) {
    const plansDetails = [
      'Cadastro ilimitado de receitas e despesas',
      'Criação ilimitada de planejamentos de gastos por categorias',
      'Análises e dicas financeiras com IA',
      'Cadastro ilimitado de objetivos financeiros',
      'Cadastro ilimitado de categorias financeiras personalizadas',
      'Importação ilimitada de transações via arquivos OFX ou CSV'
    ];

    const formatDate = (date) => {
      if (!date) {
        return;
      }

      const dateFormatted = new Date(date);
      return formatFullDate(dateFormatted);
    }

    const handleCancelSubscription = () => {
      const params = {
        subscriptionId: props.subscriptionData.id,
        cancelSubscription: true
      }

      cancelSubscription(params).then(onCancelSubscriptionSuccess).catch(onCancelSubscriptionError)
    }

    const onCancelSubscriptionSuccess = () => {
      showSuccessToast("Assinatura cancelada com sucesso!");
      sendPixelTrack('PremiumSubscriptionCanceled');
      emit('refresh-plans');
    }

    const onCancelSubscriptionError = () => {
      showErrorToast("Ocorreu um erro ao cancelar a assinatura. Tente novamente.")
    }

    const handleCancelRenewalSubscription = () => {
      const params = {
        subscriptionId: props.subscriptionData.id,
        cancelSubscription: false
      }

      cancelSubscription(params).then(onCancelRenewalSubscriptionSuccess).catch(onCancelRenewalSubscriptionError)
    }

    const onCancelRenewalSubscriptionSuccess = () => {
      showSuccessToast("Renovação automática cancelada com sucesso!");
      sendPixelTrack('PremiumRenewalCanceled');
      emit('refresh-plans');
    }

    const onCancelRenewalSubscriptionError = () => {
      showErrorToast("Ocorreu um erro ao cancelar a renovação automática. Tente novamente.")
    }

    const handleRenewalSubscription = () => {
      const params = {
        subscriptionId: props.subscriptionData.id
      }

      renewalSubscription(params).then(onRenewalSubscriptionSuccess).catch(onRenewalSubscriptionError)
    }

    const onRenewalSubscriptionSuccess = () => {
      showSuccessToast("Renovação automática ativada com sucesso!");
      sendPixelTrack('PremiumRenewalActivated');
      emit('refresh-plans');
    }

    const onRenewalSubscriptionError = () => {
      showErrorToast("Ocorreu um erro ao ativar a renovação automática. Tente novamente.")
    }

    const { open: openCancellationConfirmModal, close: closeCancellationConfirmModal } = useModal({
      component: ConfirmationModal,
      attrs: {
        buttonType: 'alert',
        title: 'Cancelar assinatura',
        description: 'Ao cancelar sua assinatura, você perderá as vantagens do Calculando Riquezas Premium. Tem certeza de que deseja cancelar?',
        onClose() {
          closeCancellationConfirmModal()
        },
        onConfirmAction() {
          handleCancelSubscription();
          closeCancellationConfirmModal()
        },
        onCancel() {
          closeCancellationConfirmModal()
        },
      }
    });

    const { open: openCancelRenewalConfirmModal, close: closeCancelRenewalConfirmModal } = useModal({
      component: ConfirmationModal,
      attrs: {
        buttonType: 'alert',
        title: 'Cancelar renovação automática',
        description: `Ao cancelar a renovação automática, seu plano será cancelado no final do período atual. 
        Você precisará assinar novamente para continuar aproveitando as vantagens do Calculando Riquezas Premium.`,
        onClose() {
          closeCancelRenewalConfirmModal()
        },
        onConfirmAction() {
          handleCancelRenewalSubscription();
          closeCancelRenewalConfirmModal()
        },
        onCancel() {
          closeCancelRenewalConfirmModal()
        },
      }
    });

    return {
      plansDetails,
      formatDate,
      openCancellationConfirmModal,
      openCancelRenewalConfirmModal,
      handleRenewalSubscription
    }
  }
}
</script>

<style lang="scss" src="./PremiumPlanBox.scss" />
