<template>
  <div class="box-page">
    <h2 class="box-page__title" v-if="title">
      {{ title }}
     <div class="box-page__header-content">
      <slot name="title"></slot>
      <slot name="actions"></slot>
     </div>
    </h2>
    <div class="box-page__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" src="./BoxPage.scss" />
