import * as usageVerificationResource from './usageVerificationResource';

export async function getAiInsightsUsage() {
    const { data } = await usageVerificationResource.getAiInsightsUsage();

    return data;
}

export async function getTransactionsImportUsage() {
    const { data } = await usageVerificationResource.getTransactionsImportUsage();

    return data;
}
